import React, { useContext } from 'react'
import styled from 'styled-components'
import { CheckoutContext } from '../context/CheckoutContext'
import { breakpoints } from '../../theme'
import { TextInput, StyledTextInput, PhoneInput } from '@monbanquet/crumble'
import { EventContext } from '../context/EventContext'
import { useWindowWidth } from '../../hooks/useWindowWidth'

const CheckoutDeliveryForm = props => {
  const {
    state: {
      deliveryCompany,
      deliveryInfos,
      doorCode,
      floorRoomStand,
      contactFirstName,
      contactLastName,
      contactEmail,
      contactPhone,
    },
    dispatch: dispatchCheckoutCtx,
  } = useContext(CheckoutContext)
  const {
    state: { address },
  } = useContext(EventContext)
  const updateCheckout = payload =>
    dispatchCheckoutCtx({ type: 'UPDATE', payload })

  const width = useWindowWidth()

  return (
    <StyledCheckoutDeliveryForm {...props}>
      <div className="section">
        <h2>Vos informations de livraison</h2>
        <div className="inputs">
          <TextInput
            className="mandatory"
            autoComplete="organization"
            label="Société à livrer"
            autoFocus={width > breakpoints.tablet}
            value={deliveryCompany}
            onChange={v => updateCheckout({ deliveryCompany: v })}
          />
          <TextInput
            className="address mandatory"
            autoComplete="street-address"
            label="Adresse de livraison"
            readOnly
            value={(address && address.formatted) || ''}
          />
          <TextInput
            label="Complément d'identification du lieu"
            autoComplete="off"
            value={deliveryInfos}
            onChange={v => updateCheckout({ deliveryInfos: v })}
          />
          <div className="inputs horizontal">
            <TextInput
              className="delivery-door-code"
              label="Code d'entrée"
              autoComplete="off"
              value={doorCode}
              onChange={v => updateCheckout({ doorCode: v })}
            />
            <TextInput
              className="delivery-floor"
              label="Étage, salle, n° de stand,..."
              autoComplete="off"
              value={floorRoomStand}
              onChange={v => updateCheckout({ floorRoomStand: v })}
            />
          </div>
        </div>
      </div>

      <div className="section">
        <h2 className="mandatory">Contact</h2>
        <div className="inputs horizontal mobile-vertical">
          <TextInput
            label="Prénom"
            autoComplete="given-name"
            value={contactFirstName}
            onChange={v => updateCheckout({ contactFirstName: v })}
          />
          <TextInput
            label="Nom"
            autoComplete="family-name"
            value={contactLastName}
            onChange={v => updateCheckout({ contactLastName: v })}
          />
        </div>
        <div className="inputs horizontal mobile-vertical">
          <TextInput
            label="Adresse email"
            autoComplete="email"
            type="email"
            value={contactEmail}
            onChange={v => updateCheckout({ contactEmail: v })}
          />
          <PhoneInput
            className="delivery-contact-phone"
            autoComplete="tel"
            label="Téléphone"
            value={contactPhone}
            onChange={v => updateCheckout({ contactPhone: v })}
          />
        </div>
      </div>
    </StyledCheckoutDeliveryForm>
  )
}

const StyledCheckoutDeliveryForm = styled.div`
  .section > .inputs {
    margin-top: 45px;

    & ~ .inputs {
      margin-top: unset;
    }
  }
  .inputs {
    display: flex;
    flex-direction: column;

    &.horizontal {
      flex-direction: row;

      ${StyledTextInput} {
        width: 50%;

        &:last-child {
          margin-left: 20px;
        }
      }

      .delivery-door-code {
        width: 25%;
      }

      .delivery-floor {
        width: 75%;
        margin-left: 20px;
      }
    }
  }

  input {
    border-radius: 8px;
    border: 2px solid #efefef;
    height: 49px;
    margin-bottom: 40px;
    padding: 20px 25px;
  }

  label {
    font-size: 17px;
    top: -5px;
    left: 0;
    color: #383838;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .inputs {
      &.horizontal {
        flex-direction: row;

        ${StyledTextInput} {
          width: 100%;

          &:last-child {
            margin-left: 12px;
          }
        }

        .delivery-door-code {
          width: 50%;
        }

        .delivery-floor {
          width: 50%;
        }
      }
      &.mobile-vertical {
        flex-direction: column;
        ${StyledTextInput} {
          width: 100%;

          &:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }
`

export default CheckoutDeliveryForm
export { StyledCheckoutDeliveryForm }
