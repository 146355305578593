import React from 'react'
import styled from 'styled-components'

const CheckboxList = ({
  tiles,
  renderTile,
  onTileSelected = () => {},
  ...props
}) => {
  return (
    <StyledCheckboxList {...props}>
      {tiles.map(tile =>
        React.cloneElement(renderTile, {
          key: tile.value,
          checked: tile.selected || false,
          onChange: selectedTile => {
            const selected = !!selectedTile.target.checked
            onTileSelected(
              { ...tile, selected },
              tiles.map(t => ({
                ...t,
                selected: tile.value === t.value ? selected : !!t.selected,
              })),
            )
          },
          ...tile,
        }),
      )}
    </StyledCheckboxList>
  )
}

const StyledCheckboxList = styled.ul``

export default CheckboxList
export { StyledCheckboxList }
