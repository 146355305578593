import React from 'react'
import styled from 'styled-components'

const FullTileFluid = ({ picture, ...props }) => (
  <StyledFullTileFluid {...props}>
    <img src={picture.original.src} alt={picture.original.src} />
  </StyledFullTileFluid>
)

const StyledFullTileFluid = styled.figure`
  img {
    width: 100%;
    height: auto;
  }
`

export default FullTileFluid
export { StyledFullTileFluid }
