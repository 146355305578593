import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import fetch from 'isomorphic-unfetch'
import CheckoutDeliveryForm, {
  StyledCheckoutDeliveryForm,
} from '../components/checkout/CheckoutDeliveryForm'
import CheckoutOrderSummary, {
  StyledCheckoutOrderSummary,
} from '../components/checkout/CheckoutOrderSummary'
import { colors, layout, breakpoints, homeLayout } from '../theme'
import { formatFullDate, formatPrice, formatTime } from '../util/format'
import { executeAtLeast, getQueryParams } from '../util'
import { EventContext } from '../components/context/EventContext'
import Link, { navigate } from '../components/Link'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { CheckoutContext } from '../components/context/CheckoutContext'
import Helmet from 'react-helmet'
import {
  Button,
  StyledButton,
  Pulse,
  VerticalDrawer,
  StyledVerticalDrawer,
} from '@monbanquet/crumble'
import CheckboxList, { StyledCheckboxList } from '../components/CheckboxList'
import CheckboxTile from '../components/CheckboxTile'

import serviceIcon from '../assets/icons/waiter.svg'
import coffeeIcon from '../assets/icons/coffee-cup.svg'
import juiceIcon from '../assets/icons/juice.svg'
import wineIcon from '../assets/icons/cheers_wine.svg'
import { PhoneContext } from '../components/context/PhoneContext'
import ArrowWhite from '../assets/arrow-white.svg'
import Arrow from '../assets/arrow.svg'
import { ParallaxProvider } from 'react-scroll-parallax'
import ParallaxShape, { StyledParallaxShape } from '../components/ParallaxShape'
import TileList, { StyledTileList } from '../components/TileList'
import FullTileFluid from '../components/FullTileFluid'
import checkIcon from '../assets/icons/check.svg'
import Img from 'gatsby-image'
import { defaultCategories } from '../components/context/EventContextDefaults'

// https://react-slick.neostack.com/docs/api#settings
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 2.1,
  lazyLoad: 'progressive',
  swipeToSlide: true,
  draggable: false,
  mobileFirst: true,
  // adaptiveHeight: true,
  responsive: [
    {
      breakpoint: breakpoints.desktop + 1,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: breakpoints.tablet + 1,
      settings: {
        slidesToShow: 2,
        arrows: false,
      },
    },
    {
      breakpoint: breakpoints.mobile + 1,
      settings: {
        slidesToShow: 1.1,
        arrows: false,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
}

const CheckoutPage = ({ pageContext }) => {
  const {
    state: { menu, startDateTime, endDateTime, address, nbGuests },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const { state: checkoutState, dispatch: dispatchCheckoutCtx } = useContext(
    CheckoutContext,
  )

  const {
    state: { e164 },
  } = useContext(PhoneContext)

  const [error, setError] = useState()

  // at page load, fill the CheckoutContext data from the EventContext address and date
  useEffect(() => {
    if (address) {
      dispatchCheckoutCtx({
        type: 'UPDATE',
        payload: {
          deliveryStreet: `${address.streetNumber} ${address.street}`.trim(),
          deliveryCity: address.city,
          deliveryZip: address.zip.toString(),
          deliveryCountry: address.country,
          deliveryStart: startDateTime,
          deliveryEnd: endDateTime,
        },
      })
    }
  }, [address])

  const [drawerOpened, setDrawerOpened] = useState(false)

  if (!menu) {
    return (
      <StyledCheckoutPage>
        <Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
        <Navbar />
        <div className="page-content">
          <StyledEmptyCheckoutPage>
            <div className="no-menu">
              <p>{`Vous n'avez pas sélectionné de menu`}</p>
            </div>
          </StyledEmptyCheckoutPage>
        </div>
      </StyledCheckoutPage>
    )
  }
  let totalExcludingTax
  let totalTvaAmount
  let totalIncludingTax

  const deliveryPrice = 30
  const deliveryTva = (deliveryPrice * 20) / 100
  if (menu.category == 'lunchbox') {
    totalExcludingTax = menu.sellingPricePerPerson * nbGuests
    totalTvaAmount = (totalExcludingTax * 10) / 100
    totalIncludingTax =
      totalExcludingTax + totalTvaAmount + deliveryPrice + deliveryTva
  } else {
    totalExcludingTax = menu.sellingPricePerPerson * nbGuests
    totalTvaAmount = (totalExcludingTax * 10) / 100
    totalIncludingTax =
      totalExcludingTax + totalTvaAmount + deliveryPrice + deliveryTva
  }

  const currentCategory = defaultCategories.filter(
    cat => cat.value === menu.category,
  )[0]

  const sendQuoteRequest = async () => {
    dispatchCheckoutCtx({
      type: 'SUBMIT',
      analytics: {
        menuId: menu.menuId,
        menuName: menu.menuName,
        menuCategory: menu.category,
        nbGuests: nbGuests,
      },
    })
    const queryParams = getQueryParams()

    const body = {
      ...checkoutState,
      company:
        checkoutState.deliveryCompany ||
        `${checkoutState.contactFirstName} ${checkoutState.contactLastName}`,
      menuId: menu.menuId,
      versionId: menu.versionId,
      menuName: menu.menuName,
      menuImageUrl:
        (menu.mainPhoto && menu.mainPhoto.thumbnails.large.url) ||
        pageContext.defaultCategoryPic,
      nbGuests: nbGuests,

      optionAlcohols: checkoutState.options.alcohols,
      optionCoffeeTea: checkoutState.options.coffeeTea,
      optionColdDrinks: checkoutState.options.coldDrinks,
      optionService: checkoutState.options.service,

      // To change when multi menu
      checkoutSubTotal: totalExcludingTax,
      checkoutDeliveryPrice: 0, // offert
      checkoutTotalExcludingTax: totalExcludingTax,
      checkoutTotalTax: totalTvaAmount,
      checkoutTotalIncludingTax: totalIncludingTax,

      // utms
      utmCampaign: queryParams['utm_campaign'],
      utmSource: queryParams['utm_source'],
      utmMedium: queryParams['utm_medium'],
      utmContent: queryParams['utm_content'],
      utmTerm: queryParams['utm_term'],
    }

    try {
      const response = await executeAtLeast(
        () =>
          fetch(`${process.env.GATSBY_VENUS_URL}/checkout-quote`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          }),
        1000,
      )

      if (response.status >= 300) {
        throw new Error(
          `Une erreur est survenue. Nous ne pouvons pas valider votre demande. Merci de nous contacter sur le chat.`,
        )
      }

      dispatchCheckoutCtx({ type: 'SUBMIT_SUCCESS' })
      navigate('/merci', { state: { order: true } })
      dispatchEventCtx({ type: 'RESET' })
      dispatchCheckoutCtx({ type: 'RESET' })
    } catch (err) {
      console.error(err)
      const errMsg = `Nous n'avons pas réussi à enregistrer votre demande. Veuillez nous contacter sur le chat ou par téléphone au ${e164}.`
      setError(errMsg)
      dispatchCheckoutCtx({
        type: 'SUBMIT_FAILED',
        payload: {
          error: errMsg,
        },
      })
    }
  }

  const updateOption = option => {
    dispatchCheckoutCtx({
      type: 'UPDATE',
      payload: {
        options: {
          ...checkoutState.options,
          ...option,
        },
      },
    })
  }

  const options = [
    {
      value: 'option-coffeetea',
      selected: checkoutState.options.coffeeTea,
      label: 'Café & thé',
      icon: coffeeIcon,
      onSelect: selected => updateOption({ coffeeTea: selected }),
    },
    {
      value: 'option-colddrinks',
      selected: checkoutState.options.coldDrinks,
      label: 'Boissons fraîches',
      icon: juiceIcon,
      onSelect: selected => updateOption({ coldDrinks: selected }),
    },
    {
      value: 'option-alcohols',
      selected: checkoutState.options.alcohols,
      label: 'Vin, bière, spiritueux',
      icon: wineIcon,
      onSelect: selected => updateOption({ alcohols: selected }),
    },
    {
      value: 'option-service',
      selected: checkoutState.options.service,
      label: 'Staff sur place',
      icon: serviceIcon,
      onSelect: selected => updateOption({ service: selected }),
    },
  ]

  let pictureTiles = []
  if (menu.mainPhotoTransformed) {
    pictureTiles = [
      {
        id: menu.mainPhotoTransformed,
        picture: menu.mainPhotoTransformed.childImageSharp,
      },
    ]
    menu.productsPhotosTransformed.forEach(item => {
      pictureTiles.push({
        id: item.id,
        picture: item.childImageSharp,
      })
    })
  }
  return (
    <StyledCheckoutPage>
      <Helmet title={`${menu.menuName} • ${process.env.GATSBY_NAME}`} />
      <Navbar />
      <ParallaxProvider>
        <div className="page-content">
          <div className="wrapper">
            <div className="shapes-container">
              <ParallaxShape
                x={-10}
                y={15}
                shapeStyle={{
                  width: '400px',
                  height: '400px',
                  border: '140px solid ' + colors.brand.beige,
                  borderRadius: '50%',
                  opacity: '0.5',
                }}
              />
              <ParallaxShape
                x={80}
                y={0}
                shapeStyle={{
                  width: '400px',
                  height: '200px',
                  border: '140px solid ' + colors.brand.beige,
                  borderBottom: 'none',
                  borderTopLeftRadius: '400px',
                  borderTopRightRadius: '400px',
                  transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                  opacity: '0.5',
                }}
              />
              <ParallaxShape
                x={85}
                y={45}
                shapeStyle={{
                  width: '400px',
                  height: '400px',
                  border: '140px solid ' + colors.brand.beige,
                  borderRadius: '50%',
                  opacity: '0.5',
                }}
              />
            </div>
            <div className="form">
              <p className="title">
                Votre commande
                <Link to={`/menus/${menu.fileName}`} className="back-btn">
                  <img src={ArrowWhite} alt="back" /> Retour
                </Link>
              </p>
              <div className="section recap">
                <h2>Menu</h2>
                <h1>
                  {menu.menuName}
                  {currentCategory && <span>{currentCategory.label}</span>}
                </h1>
                {menu.mainPhotoTransformed ? (
                  <TileList
                    settings={settings}
                    tiles={pictureTiles}
                    renderTile={<FullTileFluid />}
                  />
                ) : (
                  <Img
                    fluid={pageContext.defaultCategoryPic.fluid}
                    alt={pageContext.defaultCategoryPic.title}
                    style={imgStyle}
                    loading="lazy"
                  />
                )}
              </div>

              <div className="section">
                <h2>Livraison</h2>
                <div className="delivery-info">
                  <p className="delivery-date">
                    <img src={checkIcon} alt="check" />
                    <span>
                      <b>{formatFullDate(startDateTime)}</b>
                      <br />
                      entre <b>{formatTime(startDateTime)} </b>
                      et <b>{formatTime(endDateTime)}</b> <br />
                      <Link to={`/menus/${menu.fileName}`} className="edit">
                        <img src={Arrow} alt="back" /> Modifier
                      </Link>
                    </span>
                  </p>

                  <p>
                    <img src={checkIcon} alt="check" />
                    <span>
                      {address && address.street}
                      <br />
                      <b>
                        {address.zip +
                          ' ' +
                          address.city +
                          ', ' +
                          address.country}
                      </b>
                      <br />
                      <Link to={`/menus/${menu.fileName}`} className="edit">
                        <img src={Arrow} alt="back" /> Modifier
                      </Link>
                    </span>
                  </p>
                </div>
              </div>

              <div className="section options">
                <h2>Complétez le menu</h2>
                <CheckboxList
                  tiles={options}
                  onTileSelected={tile => tile.onSelect(tile.selected)}
                  renderTile={<CheckboxTile />}
                />

                <div className="notes">
                  <p>Remarques et commentaires</p>
                  <textarea
                    name="notes"
                    onChange={e =>
                      dispatchCheckoutCtx({
                        type: 'UPDATE',
                        payload: { notes: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
              <CheckoutDeliveryForm />
              <p className="error">{error}</p>
              <Button
                onClick={sendQuoteRequest}
                disabled={
                  checkoutState.submitting || !checkoutState.isFormComplete
                }
              >
                {checkoutState.submitting ? <Pulse /> : <>Recevoir mon devis</>}
              </Button>
            </div>
            <div className="checkout-summary">
              <VerticalDrawer
                opened={drawerOpened}
                onDraw={setDrawerOpened}
                handleSize={60}
              >
                <CheckoutOrderSummary
                  menuName={menu.menuName}
                  nbGuests={nbGuests}
                  totalExcludingTax={totalExcludingTax}
                  tvaAmount={totalTvaAmount}
                  sellingPriceIncludingTax={totalIncludingTax}
                  options={checkoutState.options}
                  deliveryTva={deliveryTva}
                  deliveryPrice={deliveryPrice}
                />
              </VerticalDrawer>
            </div>
          </div>
        </div>
      </ParallaxProvider>
      <Footer />
    </StyledCheckoutPage>
  )
}

const imgStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const StyledEmptyCheckoutPage = styled.div`
  .no-menu {
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
    margin: auto;
    margin-top: -${layout.navbarHeight.mobile}px;
    font-size: 1.5rem;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const StyledCheckoutPage = styled.div`
  .page-content {
    padding-top: ${layout.navbarHeight.mobile}px;
    background-color: ${colors.background.light};

    .wrapper {
      width: ${homeLayout.width};
      background-color: ${colors.background.light};
      margin-left: auto;
      margin-right: auto;
      display: flex;
      position: relative;
    }

    .shapes-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -6vw;
      right: -6vw;
      overflow: hidden;
    }

    .form {
      display: flex;
      flex-direction: column;
      width: 65%;
      z-index: 1;

      .section {
        background-color: white;
        border-radius: 5px;
        padding: 50px 75px;
        margin-bottom: 10px;
        z-index: 1;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h2 {
        font-family: Centra Bold;
        font-size: 17px;
        text-transform: uppercase;
        color: ${colors.brand.normal};
        margin-bottom: 20px;
      }

      ${StyledCheckoutDeliveryForm} {
        z-index: 1;
      }

      .title {
        font-family: Bely;
        font-size: 35px;
        display: flex;
        word-break: break-word;
        height: 50px;
        color: ${colors.brand.black};
        padding-left: 75px;
        margin-bottom: 30px;

        .back-btn {
          font-family: Centra;
          margin: 5px 0 5px auto;
          background-color: ${colors.brand.green};
          border-radius: 30px;
          font-size: 13px;
          color: white;
          padding: 5px 23px;
          display: flex;
          align-items: center;
          max-height: 40px;
          min-height: 28px;
          min-width: 100px;

          img {
            width: 20px;
            margin-right: 10px;
          }
        }
      }

      .recap {
        h1 {
          margin-bottom: 15px;
          font-size: 26px;
          color: ${colors.brand.black};
          display: flex;
          justify-content: space-between;

          span {
            font-family: Centra;
            background-color: #f5f5f5;
            border-radius: 18px;
            font-size: 14px;
            display: flex;
            align-items: center;
            padding: 10px 15px;
          }
        }

        ${StyledTileList} {
          .slick-arrow {
            height: 46px;
            width: 46px;
            top: 50%;

            &:before {
              background-size: 24px 24px;
            }

            &.slick-prev {
              left: 15px;
            }

            &.slick-next {
              right: 15px;
            }
          }

          .slick-list {
            overflow: hidden;

            .slick-track {
              margin-left: 0;

              img {
                width: 100%;
              }
            }
          }
        }
      }

      .delivery-info {
        display: flex;
        font-size: 15px;

        h2 {
          color: ${colors.brand.normal};
          text-transform: uppercase;
        }

        p {
          width: 40%;
          margin-right: 10%;
          display: flex;
          align-items: center;

          img {
            margin-right: 20px;
          }
        }

        .edit {
          font-size: 12px;
          text-decoration: underline;
          display: flex;
          align-items: center;

          img {
            height: 14px;
            width: 14px;
            margin-right: 6px;
          }
        }
      }

      .options {
        grid-area: options;
        display: flex;
        flex-wrap: wrap;

        h2 {
          flex-basis: 100%;
        }

        ${StyledCheckboxList} {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          margin: 0 -7px;

          & label {
            font-size: 1.1rem;
          }

          & > * {
            margin: 7px;
            flex: 1 1 140px;
            border: 1px solid #f5f5f5;

            &:hover {
              border-color: #c7c7c7;
              background-color: ${colors.background.semiLight};
            }
          }
        }

        .notes {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          font-size: 13px;
          width: 100%;

          textarea {
            margin-top: 8px;
            border: 1px solid #efefef;
            border-radius: 4px;
            font-size: 1rem;
            height: 100px;
            width: 100%;
            padding: 10px;
            font-weight: normal;
            color: ${colors.text.normal};
            resize: none;
            outline: none;
          }
        }
      }
      ${StyledButton} {
        padding-left: 50px;
        padding-right: 50px;
        margin: 40px 0 120px auto;
        display: block;
      }
    }

    > .error {
      margin-top: 10px;
      color: ${colors.brand.dark2};
    }
  }

  .checkout-summary {
    flex-basis: 35%;
    flex-shrink: 0;
    z-index: 1;
    padding-bottom: 236px;
  }

  .picture {
    grid-area: pic;
    position: relative;
    overflow: hidden;
    border-radius: 2px;

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: inset 0 0 7px 0px rgba(0, 0, 0, 0.1);
    }
  }

  ${StyledVerticalDrawer} {
    position: sticky;
    top: 0;
  }

  ${StyledCheckoutOrderSummary} {
    margin-left: 20px;
  }
  .error {
    margin-bottom: 10px;
  }

  .mandatory label::after,
  h2.mandatory::after {
    content: '*';
    margin-left: 5px;
    text-transform: none;
    font-weight: normal;
  }

  h2.mandatory::after {
    font-size: 0.8rem;
  }

  ${StyledVerticalDrawer} {
    top: ${layout.navbarHeight.mobile}px;
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .page-content {
      .wrapper {
        width: ${homeLayout.smallWidth};

        .form {
          display: flex;
          flex-direction: column;
          width: 65%;
          z-index: 1;

          .section {
            padding: 30px 30px;
          }
        }
        ${StyledParallaxShape} {
          display: none;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.tablet + 1}px) {
    ${StyledVerticalDrawer} {
      top: 180px;

      nav {
        position: static;
        transform: translate3d(0, 0, 0) !important;
      }
      .overlay {
        display: none !important;
      }
    }
  }

  @media (min-width: 1450px) {
    .page-content {
      padding-top: 150px;
    }
    ${StyledVerticalDrawer} {
      top: 230px;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .page-content {
      .wrapper {
        width: 100%;

        .form {
          width: 100%;

          .title {
            font-size: 30px;
            flex-direction: column-reverse;
            text-align: center;
            padding-left: 0;
          }

          .section {
            padding: 40px;
          }

          .delivery-info {
            flex-direction: column;

            p {
              width: 100%;
              margin-right: 0;
              margin-bottom: 16px;
            }
          }
          ${StyledButton} {
            margin: 30px auto 60px;
          }
        }

        ${StyledVerticalDrawer} {
          &.opened-drawer {
            nav {
              transform: translate3d(0px, calc(100% - 330px), 0px) !important;
            }
          }
          nav {
            background: #fff;
            bottom: 0;
            left: 0;
          }
          .handle {
            height: 60px;
            top: 0;
            cursor: n-resize;
          }

          &.opened-drawer .handle {
            cursor: s-resize;
          }

          .overlay {
            cursor: s-resize;
          }

          ${StyledCheckoutOrderSummary} {
            margin-left: 0;

            > div {
              width: unset;
            }
          }
        }
      }
    }
  }
`
export default CheckoutPage
