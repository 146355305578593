import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from '../../theme'
import { formatPrice } from '../../util/format'
import Arrow from '../../assets/arrow.svg'

const CheckoutOrderSummary = ({
  menuName,
  nbGuests,
  totalExcludingTax = 0,
  tvaAmount = 0,
  sellingPriceIncludingTax = 0,
  options,
  deliveryPrice,
  deliveryTva,
}) => {
  const optionLine = label => (
    <div className="line extra">
      <div className="label">{label}</div>
      <div className="price">Sur devis</div>
    </div>
  )

  return (
    <StyledCheckoutOrderSummary>
      <div>
        <h2>
          Récapitulatif
          <img src={Arrow} alt="arrow" />
        </h2>
        <b>{menuName}</b>
        <p>{nbGuests} convives</p>
        <hr />
        <div className="line total">
          <div className="label">A partir de </div>
          <div className="price">{formatPrice(totalExcludingTax)} HT</div>
        </div>
        {/**   NE PAS AFFICHER LES PRIX EN DETAIL !!!!!
          
        <div className="line">
          <div className="label">TVA</div>
          <div className="price">{formatPrice(tvaAmount)}</div>
        </div>

        <div>
          <div className="line">
            <div className="label">Livraison et frais annexes</div>
            <div className="price">{formatPrice(deliveryPrice)}</div>
          </div>
          <div className="line">
            <div className="label">TVA</div>
            <div className="price">{formatPrice(deliveryTva)}</div>
          </div>
        </div>

        <hr />
        <div className="line total">
          <div className="label">Total TTC</div>
          <div className="price total-price">
            {formatPrice(sellingPriceIncludingTax)}
          </div>
        </div>
         * 
           */}
        {options.coffeeTea && optionLine('Café & thé')}
        {options.coldDrinks && optionLine('Boissons fraîches')}
        {options.alcohols && optionLine('Vin, bière, spiritueux')}
        {options.service && optionLine('Service sur place')}
      </div>
    </StyledCheckoutOrderSummary>
  )
}

const StyledCheckoutOrderSummary = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    padding: 50px;
    width: 400px;
  }

  h2 {
    font-family: Centra Bold;
    font-size: 17px;
    margin-bottom: 20px;
    color: ${colors.brand.normal};
    text-transform: uppercase;

    img {
      display: none;
      transform: rotate(90deg);
      height: 20px;
      width: 20px;
    }
  }

  hr {
    margin: 10px 0;
  }

  .line {
    display: flex;
    margin-bottom: 10px;

    &.total {
      margin-top: 10px;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    &.extra > .price {
      color: ${colors.text.semiLight};
    }

    & > div {
      flex-grow: 1;
    }

    & .price {
      text-align: right;
    }
  }

  .total-price {
    font-weight: bold;
  }

  @media (max-width: 1200px) {
    & > div {
      padding: 30px;
      width: 300px;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    & > div {
      padding-top: 100px;
    }

    h2 {
      font-family: Bely;
      background-color: ${colors.secondary.yellow};
      color: ${colors.brand.black};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;

      img {
        display: block;
      }
    }
  }
`

export default CheckoutOrderSummary
export { StyledCheckoutOrderSummary }
